import "antd/lib/dropdown/style";
import _Dropdown from "antd/lib/dropdown";
import "antd/lib/button/style";
import _Button from "antd/lib/button";
import "antd/lib/card/style";
import _Card from "antd/lib/card";
var __jsx = React.createElement;
import React, { useState } from 'react';
import { QuestionCircleOutlined, DownOutlined } from '@ant-design/icons';
import getDomainSpecificContent from 'utils/getDomainSpecificContent';
var HelpButton = function HelpButton() {
  var _useState = useState(false),
    visible = _useState[0],
    setVisible = _useState[1];
  var menuItems = [{
    label: __jsx(_Card, {
      size: "small",
      style: {
        padding: '1em',
        width: '265px'
      }
    }, getDomainSpecificContent('HelpButton')),
    key: 'help-button-contents'
  }];
  return __jsx(_Dropdown, {
    open: visible,
    onOpenChange: function onOpenChange(v) {
      return setVisible(v);
    },
    menu: {
      items: menuItems
    },
    placement: "bottomRight",
    trigger: "click"
  }, __jsx(_Button, {
    type: "dashed",
    icon: __jsx(QuestionCircleOutlined, null)
  }, "Need help?", __jsx(DownOutlined, null)));
};
export default HelpButton;