import "antd/lib/page-header/style";
import _PageHeader from "antd/lib/page-header";
import "antd/lib/space/style";
import _Space from "antd/lib/space";
var __jsx = React.createElement;
import "core-js/modules/es6.function.name.js";
import React from 'react';
import { NextSeo } from 'next-seo';
import { useSelector } from 'react-redux';
import integrationTestConstants from 'utils/integrationTestConstants';
import UserButton from 'components/header/UserButton';
import FeedbackButton from 'components/header/FeedbackButton';
import ReferralButton from 'components/header/ReferralButton';
import HelpButton from 'components/header/HelpButton';
import getDomainSpecificContent from 'utils/getDomainSpecificContent';
var Header = function Header(props) {
  var experimentId = props.experimentId,
    experimentData = props.experimentData,
    title = props.title,
    extra = props.extra;
  var experiment = useSelector(function (state) {
    return state === null || state === void 0 ? void 0 : state.experiments[experimentId];
  });
  var experimentName = (experimentData === null || experimentData === void 0 ? void 0 : experimentData.experimentName) || (experiment === null || experiment === void 0 ? void 0 : experiment.name);
  var truncateText = function truncateText(text) {
    return text && text.length > 28 ? "".concat(text.substr(0, 27), "\u2026") : text;
  };
  return __jsx(React.Fragment, null, __jsx(NextSeo, {
    title: experimentData ? "".concat(title, " \xB7 ").concat(truncateText(experimentName)) : title
  }), __jsx(_PageHeader, {
    className: integrationTestConstants.classes.PAGE_HEADER,
    title: title,
    style: {
      width: '100%',
      paddingTop: '10px',
      paddingBottom: '10px'
    },
    extra: __jsx(_Space, {
      size: "large"
    }, __jsx(_Space, null, getDomainSpecificContent('HeaderExtraButton'), __jsx(HelpButton, null), __jsx(FeedbackButton, null), __jsx(ReferralButton, null), extra), __jsx(UserButton, null))
  }));
};
Header.defaultProps = {
  experimentId: null,
  experimentData: null,
  extra: __jsx(React.Fragment, null)
};
export default Header;